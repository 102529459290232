*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}
body{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
}
header {
  border-bottom: 1px solid #eee;
  margin-bottom:20px;
  padding: 25px 0;
}
.container{
  max-width: 980px;
  margin: 0 auto;
}
.galaryList {

}
.galaryList a {
  display: block;
  padding: 20px;
  border: 2px solid #000;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 15px;
  color:#000;
}
.galaryList a:hover {
  background-color: #eee;
}
.buttonArea {
  padding: 15px 0;
}
button {
  cursor:pointer;
  padding: 10px;
  border:none;
  background-color:#000;
  color:#fff;
  text-transform: uppercase;
}
button:hover {
  background-color:#555;
}
.albumArea {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap:20px;
}
img {
  padding: 10px;
  border: 1px solid #000;
}
.title {
  font-size: 18px;
  margin-bottom: 18px;
  font-weight: bold;
}

@media (max-width: 980px) {
  .container{
    padding: 0 15px;
  }
}

@media (max-width: 800px) {
  .albumArea {
    grid-template-columns: repeat(2, 1fr);
  }
  img {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .albumArea {
    grid-template-columns: 1fr;
  }
}